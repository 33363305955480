import { ProposalCollectionResource }        from '@/models/proposals/ProposalCollectionResource'
import { ProposalResource }                  from '@/models/proposals/ProposalResource'
import { ProposalSummaryCollectionResource } from '@/models/proposals/ProposalSummaryCollectionResource'
import { ProposalCollectionLightResource }   from '@/models/proposals/ProposalCollectionLightResource'

export class ProposalsDeleteRequest {
  public proposal_ids: number[] = []

  constructor(proposals?: Array<ProposalCollectionResource | ProposalResource | ProposalCollectionLightResource>) {
    this.proposal_ids = proposals?.map(({ id }) => id) ?? []
  }
}
