import { ProposalCollectionResource } from '@/models/proposals/ProposalCollectionResource'

import { canPerformAction } from '@/helpers/canPerformAction'

import { createSocket }                                            from '@/api'
import { Socket }                                                  from 'socket.io-client'
import { ProposalService }                                         from '@/services/proposal'

import { IProposalResource, PROPOSAL_EVENTS, ProposalPermissions } from '@/models/proposals/ProposalResource'
import { StatusResource }                                          from '@/models/status/StatusResource'
import { ProjectUserResource }                                     from '@/models/projects/ProjectUserResource'

export class ProposalCollectionLightResource {
  public id: number
  public proposal_name: string
  public description: string
  public status: StatusResource
  public project_id: number
  public process_id: number
  public inherited?: boolean
  public permissions: ProposalPermissions[]
  public is_locked?: boolean
  public allocated_users: ProjectUserResource[]
  public isLight: boolean = true
  public channel: string

  private socket?: Socket
  private proposalService: ProposalService

  constructor(proposal: ProposalCollectionResource) {
    this.id = proposal.id
    this.proposal_name = proposal.proposal_name
    this.description = proposal.description
    this.status = proposal.status
    this.project_id = proposal.project_id
    this.process_id = proposal.process_id
    this.permissions = proposal.permissions ?? []
    this.is_locked = proposal.is_locked
    this.allocated_users = proposal.allocated_users
    this.channel = proposal.channel

    this.proposalService = new ProposalService({ project_id: proposal.project_id, process_id: proposal.process_id, proposal_id: proposal.id })

    this.openSocket()
  }

  public destroy(): void {
    this.socket?.off('event', (e: EventResponse) => this.handleEvent(e))
  }

  public canPerformAction(key: ProposalPermissions): boolean {
    return canPerformAction<ProposalPermissions>(this.permissions, key)
  }

  public async refresh(): Promise<ProposalCollectionLightResource> {
    const { data } = await this.proposalService.refresh()
    this.updateData(data)
    return this
  }

  private updateData(proposal: IProposalResource): void {
    this.proposal_name = proposal.proposal_name
    this.status = new StatusResource(proposal.status)
    this.permissions = proposal.permissions
    this.allocated_users = proposal.allocated_users?.map((user) => new ProjectUserResource(user)) ?? []
  }


  private handleEvent(res: EventResponse): void {
    switch (res.data.event) {
      case PROPOSAL_EVENTS.UPDATED:
        this.refresh()
    }
  }

  private async openSocket(): Promise<void> {
    this.socket = await createSocket(this.channel)
    this.socket.on('event', (e: EventResponse) => this.handleEvent(e))
  }
}

export const isProposalCollectionLightResource = (proposal: ProposalCollectionResource | ProposalCollectionLightResource): proposal is ProposalCollectionLightResource => {
  return 'isLight' in proposal
}
