

































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

import { ProcessResource }                   from '@/models/process/ProcessResource'
import { ProposalSummaryCollectionResource } from '@/models/proposals/ProposalSummaryCollectionResource'

import NoItems       from '@/components/partials/NoItems.vue'
import QuickListItem from '@/components/listItem/QuickListItem.vue'
import {ProposalCollectionLightResource} from '@/models/proposals/ProposalCollectionLightResource'
import {ProposalCollectionResource} from '@/models/proposals/ProposalCollectionResource'

@Component({
  components: {
    NoItems,
    QuickListItem
  }
})
export default class QuickList extends Vue {

  @Prop()
  private proposals!: Array<ProposalCollectionLightResource | ProposalCollectionResource>

  @Prop()
  private process!: ProcessResource

  @Prop({ default: 'document' })
  private type!: string

  private open: boolean = true

  private closeMenu(): void {
    if (this.open) {
      this.toggleMenu()
    }
  }

  private toggleMenu(): void {
    this.open = !this.open
    this.$emit('toggle')
  }
}
