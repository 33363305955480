


















import truncate                 from 'lodash/truncate'
import { RawLocation }          from 'vue-router'
import { Component, Vue, Prop } from 'vue-property-decorator'

import StatusCard from '@/components/misc/StatusCard.vue'

import { ProposalCollectionResource } from '@/models/proposals/ProposalCollectionResource'
import {isProposalCollectionLightResource, ProposalCollectionLightResource} from '@/models/proposals/ProposalCollectionLightResource'

@Component({
  components: {StatusCard},
})
export default class QuickListItem extends Vue {

  @Prop({ required: true })
  private proposal!: ProposalCollectionResource | ProposalCollectionLightResource

  @Prop()
  private selected!: boolean

  @Prop({ default: true })
  private canNavigate!: boolean

  @Prop({ default: false })
  private canRemove!: boolean

  @Prop({ default: 'Remove from selection' })
  private removeText!: boolean

  private get truncatedProposalName(): string {
    return truncate(this.proposal?.proposal_name, { length: 60 }) || ''
  }

  private isProposalActive(proposal: ProposalCollectionResource | ProposalCollectionLightResource): boolean {
    if (!this.$route.params.proposal_id) return false
    return +this.$route.params.proposal_id === proposal.id
  }

  private beforeDestroy(): void {
    if (isProposalCollectionLightResource(this.proposal)) {
      this.proposal.destroy()
    }
  }

  private get route(): RawLocation {
    return {
      name: 'projects-detail-proposal-detail',
      params: {
        project_id: this.proposal.project_id.toString(),
        process_id: this.proposal.process_id.toString(),
        proposal_id: this.proposal.id.toString()
      }
    }
  }

  private addHover(): void {
    const nodes = document.getElementsByClassName(`proposal-id-${this.proposal.id}`)
    for (const node of nodes) {
      node.classList.add('hover')
    }
  }

  private removeHover(): void {
    const nodes = document.getElementsByClassName(`proposal-id-${this.proposal.id}`)
    for (const node of nodes) {
      node.classList.remove('hover')
    }
  }
}
