

























































































































import {projectModule} from '@/store'
import {RawLocation} from 'vue-router'
import {Component, Prop, Vue} from 'vue-property-decorator'

import {ProcessResource} from '@/models/process/ProcessResource'
import {StatusResourceCollection} from '@/models/status/StatusResourceCollection'
import {ProposalsLockRequest} from '@/requests/proposals/multiple/ProposalsLockRequest'
import {ProposalCollectionResource} from '@/models/proposals/ProposalCollectionResource'
import {ProposalsDeleteRequest} from '@/requests/proposals/multiple/ProposalsDeleteRequest'
import {ProposalCollectionLightResource} from '@/models/proposals/ProposalCollectionLightResource'
import {ProposalsStatusPatchRequest} from '@/requests/proposals/multiple/ProposalsStatusPatchRequest'
import {ProposalsAllocateUsersRequest} from '@/requests/proposals/multiple/ProposalsAllocateUsersRequest'

import Tabs from '@/components/widgets/Tabs.vue'
import Modal from '@/components/widgets/Modal.vue'
import Submit from '@/components/project/Submit.vue'
import DefaultModal from '@/components/modals/Default.vue'
import UserPicker from '@/components/inputs/UserPicker.vue'
import StatusPicker from '@/components/inputs/StatusPicker.vue'
import StatusBadge from '@/components/statuses/StatusBadge.vue'
import DetailsHeader from '@/components/header/DetailsHeader.vue'
import ResponseModal from '@/components/widgets/ResponseModal.vue'
import QuickListItem from '@/components/listItem/QuickListItem.vue'

type ClonedLocation = RawLocation

@Component({
  components: {
    Tabs,
    Modal,
    Submit,
    UserPicker,
    StatusBadge,
    StatusPicker,
    DefaultModal,
    ResponseModal,
    DetailsHeader,
    QuickListItem
  }
})
export default class ProposalMultiSelect extends Vue {

  @Prop()
  private process!: ProcessResource

  @Prop()
  private proposals!: Array<ProposalCollectionResource | ProposalCollectionLightResource>

  @Prop()
  private backRoute!: ClonedLocation

  private showCreateChildModal: boolean = false

  private changeStatusModal: {
    loading: boolean
    open: boolean
    status: StatusResourceCollection | null
  } = {
    loading: false,
    open: false,
    status: null
  }

  private responseModal: {
    open: boolean
    status: StatusResourceCollection | null
  } = {
    open: false,
    status: null
  }

  private allocateUsersForm: { loading: boolean, form: ProposalsAllocateUsersRequest } = {
    loading: false,
    form: new ProposalsAllocateUsersRequest()
  }

  private deleteWarningModalShown: boolean = false
  private deleting: boolean = false

  private locking: boolean = false

  private get nextProcess(): ProcessResource | null {
    return projectModule.getProcessByOrder((this.process.order ?? 0) + 1 ?? 0)
  }

  private get loading() {
    return this.deleting || this.locking
  }

  private get projectUsers() {
    return projectModule.users
  }

  private get proposalIds() {
    return this.proposals.map(({id}) => id)
  }

  private get currentStatus() {
    const allProposalsHaveSameStatus = this.proposals.every((proposal) => proposal.status.value === this.proposals[0]?.status.value)
    if (allProposalsHaveSameStatus) {
      return this.proposals[0]?.status
    } else {
      return new StatusResourceCollection({color: '#767676', id: 0, label: 'Mixed', substantiation: false, value: '', verb: 'mixed'})
    }
  }

  private get isAProposalLocked() {
    return this.proposals.some(({is_locked}) => is_locked)
  }

  private get areAllProposalsLocked() {
    return this.proposals.every(({is_locked}) => is_locked)
  }

  private get canCreateProposalOnAllProposals() {
    return this.proposals.every((proposal) => proposal.canPerformAction('can_create_proposal'))
  }

  private get canToggleLocksOnProposals() {
    return this.proposals.every((proposal) => proposal.canPerformAction('can_toggle_lock'))
  }

  private get canDeleteOnProposals() {
    return this.proposals.every((proposal) => proposal.canPerformAction('can_delete_proposal'))
  }

  private get canChangeStatusOnProposals() {
    return this.proposals.every((proposal) => proposal.canPerformAction('can_change_status'))
  }

  private get proposalsThatCantConnect() {
    return this.proposals.filter((proposal) => !proposal.canPerformAction('can_create_proposal'))
  }

  private get proposalCantConnectProposalsWarning() {
    if (this.proposalsThatCantConnect.length === 1) {
      return `Some selected ${this.process.process_name} are not qualified to connect. Please deselect: ${this.proposalsThatCantConnect.map(({proposal_name}) => proposal_name).join(', ') }`
    } else {
      return `Some selected ${this.process.process_name} are not qualified to connect. Please deselect the following ${this.process.process_name}: ${this.proposalsThatCantConnect.map(({proposal_name}) => proposal_name).join(', ') }`
    }
  }

  private get proposalsCantDeleteWarning() {
    return `${this.process.process_name} cannot be deleted while locked.`
  }

  private get proposalsCantChangeStatusWarning() {
    return `${this.process.process_name} cannot change status while locked.`
  }

  private get proposalsCantAllocateUsersWarning() {
    return `${this.process.process_name} cannot allocate users while locked.`
  }

  private removeFromSelection(proposal: number) {
    this.$emit('removeProposal', proposal)
  }

  private startUpdateStatusProcess(statusValue: string) {
    this.changeStatusModal.status = this.process?.statuses.find((status) => statusValue === status.value) ?? null
    if (this.changeStatusModal.status) {
      this.changeStatusModal.open = true
    }
  }

  private closeChangeStatusModal() {
    this.changeStatusModal.open = false
  }

  private openResponseModal(status: StatusResourceCollection): void {
    this.responseModal.status = status
    this.responseModal.open = true
  }

  private closeResponseModal(): void {
    this.responseModal.open = false
    this.responseModal.status = null
  }


  private async patchStatus(form: { message?: string, userIds?: number[] }): Promise<void> {
    if (!this.changeStatusModal.status || !this.changeStatusModal.status.value) {
      return
    }

    this.changeStatusModal.loading = true

    const body: ProposalsStatusPatchRequest = new ProposalsStatusPatchRequest(this.proposals)
    body.status = this.changeStatusModal.status.value

    if (this.changeStatusModal.status.need_allocation) {
      body.notify_user = form.userIds
    }

    if (this.changeStatusModal.status.substantiation) {
      body.status_message = form.message
    }


    try {
      await this.process.patchStatusOfProposals(body)
      this.closeChangeStatusModal()
      this.openResponseModal(this.changeStatusModal.status)
    } catch (e) {
      console.error(e)
    } finally {
      this.changeStatusModal.loading = false
    }
  }

  private cancelAllocation(toggle: () => void) {
    this.allocateUsersForm.form = new ProposalsAllocateUsersRequest()
    toggle()
  }

  private async allocateUsers(toggle: () => void) {
    this.allocateUsersForm.form.proposal_ids = this.proposalIds
    this.allocateUsersForm.loading = true
    try {
      await this.process.allocateUsersToProposals(this.allocateUsersForm.form)
      toggle()
    } catch (e) {
      console.error(e)
    } finally {
      this.allocateUsersForm.loading = false
    }
  }

  private confirmDelete(): void {
    this.deleteWarningModalShown = true
  }

  private cancelDelete(): void {
    this.deleteWarningModalShown = false
  }

  private async deleteProposals(): Promise<void> {
    const body = new ProposalsDeleteRequest(this.proposals)

    this.deleting = true
    try {
      await this.process.deleteProposals(body)
      this.deleteWarningModalShown = false
      this.$emit('refresh')
      this.$emit('close')
    } finally {
      this.deleting = false
    }
  }

  private async toggleLock() {
    this.locking = true
    try {
      const body = new ProposalsLockRequest(this.proposals)
      await this.process.toggleLockOfProposals(body)
    } finally {
      this.locking = false
    }
  }

  private createChild() {
    this.showCreateChildModal = true
  }

}
