import { ProposalResource }           from '@/models/proposals/ProposalResource'
import { ProposalCollectionResource } from '@/models/proposals/ProposalCollectionResource'

export class ProposalsAllocateUsersRequest {
  public proposal_ids: number[]
  public notify_user: number[] = []

  constructor(proposals?: Array<ProposalCollectionResource | ProposalResource>) {
    this.proposal_ids = proposals?.map(({ id }) => id) ?? []
  }
}
