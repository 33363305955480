








import { Component, Vue, Prop } from 'vue-property-decorator'
import { StatusResourceCollection } from '@/models/status/StatusResourceCollection'
import { StatusResource } from '@/models/status/StatusResource'
import { hexToRgb } from '@/helpers/colors'

@Component
export default class StatusCard extends Vue {

  @Prop()
  private status!: StatusResourceCollection | StatusResource | undefined

  @Prop({ default: false })
  private truncated!: boolean

  private get truncatedStatus(): string {
    return this.status?.label.substr(0, 1).toUpperCase() ?? ''
  }

  private get statusLabel(): string {
    return this.status?.label ?? ''
  }

  private get style(): Dictionary<string> {
    if (this.status) {
      return {
        color: this.status.color,
        backgroundColor: `rgba(${hexToRgb(this.status.color)}, .2)`,
      }
    }

    return {}
  }
}
