





















import {RawLocation} from 'vue-router'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import ProposalDetail from '@/components/proposal/detail/Index.vue'

import {ProcessResource} from '@/models/process/ProcessResource'
import {ProposalResource} from '@/models/proposals/ProposalResource'
import {ProposalCollectionResource} from '@/models/proposals/ProposalCollectionResource'

import MultiProposals from '@/views/dashboard/projects/detail/process/partials/MultiProposals.vue'
import {ProposalCollectionLightResource} from '@/models/proposals/ProposalCollectionLightResource'

@Component({
  components: {
    ProposalDetail,
    MultiProposals
  }
})
export default class ProposalSelect extends Vue {

  @Prop()
  private process!: ProcessResource

  @Prop()
  private proposals!: Array<ProposalCollectionResource | ProposalCollectionLightResource>

  private firstSelectedProposal: ProposalResource | null = null

  private fetchingFirstSelected: boolean = false

  private get backRoute(): RawLocation {
    const params = {...this.$route.params}
    delete params.proposal_id
    return {name: 'projects-detail-process', params}
  }

  @Watch('proposals.length', {immediate: true})
  private onSelectedProposalsChange(proposalCount: number): void {
    if (proposalCount === 1 && this.firstSelectedProposal?.id !== this.proposals[0]?.id) {
      this.getProposal(this.proposals[0].id)
    }
  }

  private async getProposal(id: number): Promise<void> {
    this.fetchingFirstSelected = true
    try {
      const {data} = await this.process.getProposal(id)
      this.$set(this, 'firstSelectedProposal', data)
    } finally {
      this.fetchingFirstSelected = false
    }
  }

  private removeFromSelection(proposal: number) {
    this.$emit('deselectProposal', proposal)
  }

}
